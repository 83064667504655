import React, {useEffect, useState } from "react";

//https://codepen.io/Alca/pen/wjxLmK
//import logo from './logo.svg';
import './App.scss';

function IpInformation(props) {
	const [ipData, setIpData] = useState(null);

	const fetchApi = async () => {
		let response = await fetch("https://api.hx.io/v1/utilities/ip/");
		//response = await fetch("https://ip.hx.io/api/");
		console.log("Received response of " + response.status + ": " + response.statusText);
		if (!response.ok) {
			console.log("Error!");
			setIpData(null);
			return;
		}
		let text = await response.text();
		console.log(text);
		const data = text && JSON.parse(text);
		setIpData(data);
	}

	useEffect(() => {
		fetchApi();
	}, []); // eslint-disable-line

	if (!ipData) {
		return(
			<div>Loading...</div>
		)
	} else {
		return(
				<dl>
					<dt>IP Address</dt>
					<dd>
						<span className="ip">{ipData.ip_address}</span> (ASN: <a href={'https://mxtoolbox.com/SuperTool.aspx?action=asn%3a' + ipData.maxmind_asn.autonomous_system_number + '&run=toolpage'} target="_blank" rel="noreferrer">{ipData.maxmind_asn.autonomous_system_number}</a> {ipData.maxmind_asn.autonomous_system_organization})
					</dd>
					<dt>Location</dt>
					<dd>
						{ ipData.maxmind_city.city && ipData.maxmind_city.city.names.en }
						{ ipData.maxmind_city.subdivisions && ", " + ipData.maxmind_city.subdivisions[0].names.en + ", " }
						{ ipData.maxmind_city.country && ipData.maxmind_city.country.names.en }
						<br />
						{ipData.maxmind_city.location.latitude}&deg;/{ipData.maxmind_city.location.longitude}&deg;
					</dd>
					{/* <dt>User Agent</dt>
					<dd>{ipData['user-agent']}</dd> */}
				</dl>
		)	
	}
// {ipData.maxmind_city.city.names.en}, {ipData.maxmind_city.subdivisions[0].names.en}, {ipData.maxmind_city.country.names.en}<br />{ipData.maxmind_city.location.latitude}&deg;/{ipData.maxmind_city.location.longitude}&deg;
}

function App() {
	return (
		<div className="App">
			{/* <h1 className="pretty">ip.hx.io</h1> */}
			<div className="IpInformation">
			<IpInformation></IpInformation>
			</div>
		</div>
	);
}

export default App;
